<template>
    <div class="mobile">
      <MobileHeader :tab-select="tabSelect"/>
      <div class=" main-con clearfix">
        <div class="detail-con">
          <div class="title">聯繫我們</div>
          <div class="article">香港公益539將竭誠為您提供最及時、最便捷、及滿意的服務。若有問題可透過以下方式與我們取得聯繫。</div>
        </div>
      </div>
 
      <MobileFooter :tab-select="tabSelect"/>
  
    </div>
  </template>
  
  <script>
  import MobileHeader from "./MobileHeader";
import MobileFooter from "./MobileFooter";
import axios from "axios";

export default {
  components: {
    MobileHeader,
    MobileFooter,
  },
    
    data() {
      return {
        kaijggList: [],
        lunbtList: [],
        daohcdList: [],
        tabSelect: 5,
      };
    },
  
    // 注入全局变量
    inject: ["imageUrl", "serviceUrl"],
  
    mounted() {
      let that = this;
  
      axios
        .get(that.serviceUrl + "/kaijgg/ayKaijgg/list", {
          params: {},
        })
        .then((resp) => {
          that.kaijggList = resp.data.result.records;
        })
        .catch((error) => {
          console.log(error);
        });
    },
  
    methods: {
      jump(path) {
        this.$router.push(path);
      },
    },
  };
  </script>
  
  <style scoped></style>
  