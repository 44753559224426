<template>
  <div>
    <PcHeader :tab-select="tabSelect" />
    <div class="main-con clearfix">
      <div class="nav"><span>開獎公告</span></div>
      <div class="header">
        <ul>
          <li class="active">香港公益539</li>
        </ul>
      </div>
      <div class="notice">
        <span class="left"> 重要通知： </span>
        <span>
          香港公益539攪珠直播時間為每周一.周三.周五晚
          <span class="phone"> 21:30 </span>
          (北京時間)，敬請查看。
        </span>
      </div>
      <div class="clear h20"></div>
      <div class="block-left all-top border clearfix">
        <div class="con clearfix">
          <div class="fxl l-con">
            <div class="title">
              澳門銀河娛樂第 <span class="font-red">{{ qih }}</span> 期
            </div>
            <div class="num">
              <span class="ball"
                ><span :class="ballColor(kaijhm[0])">{{ kaijhm[0] }}</span></span
              >
              <span class="ball"
                ><span :class="ballColor(kaijhm[1])">{{ kaijhm[1] }}</span></span
              >
              <span class="ball"
                ><span :class="ballColor(kaijhm[2])">{{ kaijhm[2] }}</span></span
              >
              <span class="ball"
                ><span :class="ballColor(kaijhm[3])">{{ kaijhm[3] }}</span></span
              >
              <span class="ball"
                ><span :class="ballColor(kaijhm[4])">{{ kaijhm[4] }}</span></span
              >
              <span class="ball"
                ><span :class="ballColor(kaijhm[5])">{{ kaijhm[5] }}</span></span
              >
              <span
                class="ball font-red"
                style="font-size: 20px; padding: 0px 0px 0px 5px"
                >+</span
              >
              <span class="ball"
                ><span :class="ballColor(kaijhm[6])">{{ kaijhm[6] }}</span></span
              >
            </div>
          </div>
          <div class="r-con fxr">
            <div class="fxr title">
              <div class="fxr">第 {{a}} 期開獎時間：{{b}}</div>
            </div>
            <div class="lotteryTime clearfix">
              <div class="leftTime clearfix">
                <div class="fxl hour">
                  <span class="num-border">{{Math.floor(hours / 10) }}</span>
                  <span class="num-border">{{hours % 10}}</span>
                </div>
                <span class="fxl dot dot1">:</span>
                <div class="fxl minute fxl">
                  <span class="num-border">{{Math.floor(minutes / 10) }}</span>
                  <span class="num-border">{{minutes % 10}}</span>
                </div>
                <span class="fxl dot dot2">:</span>
                <div class="second fxl">
                  <span class="num num-border">{{Math.floor(seconds / 10) }}</span>
                  <span class="num num-border">{{seconds % 10}}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="clear h20"></div>
    <div class="main-con table clearfix">
      <h1><span>香港公益539 開獎公告</span></h1>
      <el-table class="border" :data="kaijggList" style="width: 100%">
        <el-table-column prop="qih" label="期號" width="140" align="center">
          <template #default="scope">
            第 <span class="font-red">{{ scope.row.qih }}</span> 期
          </template>
        </el-table-column>
        <el-table-column
          prop="kaijrq"
          label="開獎時間"
          width="200"
          align="center"
        >
        <template #default="scope">
          {{scope.row.kaijrq}} 21:30:00
        </template>
      </el-table-column>
        <el-table-column prop="kaijhm" label="中獎號碼" align="center">
          <template #default="scope">
            <span class="ball small"
              ><span :class="ballColor(scope.row.kaijhm.split(',')[0])">{{
                scope.row.kaijhm.split(",")[0]
              }}</span></span
            >
            <span class="ball small"
              ><span :class="ballColor(scope.row.kaijhm.split(',')[1])">{{
                scope.row.kaijhm.split(",")[1]
              }}</span></span
            >
            <span class="ball small"
              ><span :class="ballColor(scope.row.kaijhm.split(',')[2])">{{
                scope.row.kaijhm.split(",")[2]
              }}</span></span
            >
            <span class="ball small"
              ><span :class="ballColor(scope.row.kaijhm.split(',')[3])">{{
                scope.row.kaijhm.split(",")[3]
              }}</span></span
            >
            <span class="ball small"
              ><span :class="ballColor(scope.row.kaijhm.split(',')[4])">{{
                scope.row.kaijhm.split(",")[4]
              }}</span></span
            >
            <span class="ball small"
              ><span :class="ballColor(scope.row.kaijhm.split(',')[5])">{{
                scope.row.kaijhm.split(",")[5]
              }}</span></span
            >
            <span class="ball font-red" style="font-size: 20px; margin: 0px"
              >+</span
            >
            <span class="ball small"
              ><span :class="ballColor(scope.row.kaijhm.split(',')[6])">{{
                scope.row.kaijhm.split(",")[6]
              }}</span></span
            >
          </template>
        </el-table-column>
        <el-table-column
          label="開獎回放"
          prop="oper"
          width="120"
          align="center"
        >
          <template #default="scope">
            <el-button
              class="btn-red"
              size="small"
              type="danger"
              @click="videoOpen(scope.$index, scope.row)"
              >回放</el-button
            >
          </template>
        </el-table-column>
      </el-table>
    </div>
    <PcFooter />
  </div>
</template>

<script>
import PcHeader from "./PcHeader";
import PcFooter from "./PcFooter";
import axios from "axios";

export default {
  components: {
    PcHeader,
    PcFooter,
  },

  data() {
    return {
      a: null,
      b: null,
      zuixyq: [],
      interval: null,
      targetDate: null,
      days: 0,
      hours: 0,
      minutes: 0,
      seconds: 0,

      qih: "",
      kaijhm: [],

      kaijggList: [],
      lunbtList: [],
      daohcdList: [],
      tabSelect: 1,
    };
  },

  computed: {
    ballColor(num) {
      return function (num) {
        const red = [1, 2, 7, 8, 12, 13, 18, 19, 23, 24, 29, 30, 34, 35, 40, 45, 46];
        const green = [5, 6, 11 , 16, 17, 21, 22, 27, 28, 32, 33, 38, 39, 43, 44, 49];
        const blue = [3, 4, 9, 10, 14, 15, 20, 25, 26, 31, 36, 37, 41, 42, 47, 48];
        
        if(red.includes(parseInt(num)))
          return "balls red"
        if(green.includes(parseInt(num)))
          return "balls green"
        if(blue.includes(parseInt(num)))
          return "balls blue"
        
      }
    },
  },

  // 注入全局变量
  inject: ["imageUrl", "serviceUrl"],

  mounted() {
    let that = this;

    axios
      .get(that.serviceUrl + "/sys/api/queryDictItemsByCode", {
        params: { code: "zuixyq" },
      })
      .then((resp) => {
        that.zuixyq = resp.data;
        
        let tmp = that.zuixyq[1].value
        tmp = tmp.replace(' ', 'T');
        that.targetDate = new Date(tmp + "+08:00")

        that.calculateTime();
        // 每秒更新一次倒计时
        that.interval = setInterval(() => {
          that.calculateTime();
        }, 1000);

        that.a = that.zuixyq[0].value
        that.b = that.zuixyq[1].value
        
      })
      .catch((error) => {
        console.log(error);
      });

    axios
      .get(that.serviceUrl + "/kaijgg/ayKaijgg/list", {
        params: {
          shifxs: "1",
          column: "qih",
          order: "desc",
          pageNo: 1,
          pageSize: 365,
        },
      })
      .then((resp) => {
        that.kaijggList = resp.data.result.records;
        that.kaijhm = resp.data.result.records[0].kaijhm.split(",");
        that.qih = resp.data.result.records[0].qih;

      })
      .catch((error) => {
        console.log(error);
      });
  },

  methods: {
    loadData() {
      let that = this

      axios
      .get(that.serviceUrl + "/kaijgg/ayKaijgg/list", {
        params: {
          shifxs: "1",
          column: "qih",
          order: "desc",
          pageNo: 1,
          pageSize: 365,
        },
      })
      .then((resp) => {
        that.kaijggList = resp.data.result.records;
        that.kaijhm = resp.data.result.records[0].kaijhm.split(",");
        that.qih = resp.data.result.records[0].qih;
      })
      .catch((error) => {
        console.log(error);
      });
    },

    kaij() {
      let that = this

      axios
      .put(that.serviceUrl + "/kaijgg/ayKaijgg/kaij", {
        params: {
        },
      })
      .then((resp) => {
        that.loadData()
      })
      .catch((error) => {
        console.log(error);
      });
    },
    
    calculateTime() {
      const now = new Date();
      const difference = this.targetDate - now;

      // Calculate total hours remaining
      const totalHours = Math.floor(difference / (1000 * 60 * 60));

      // Calculate days, hours, minutes, and seconds
      this.hours = totalHours;
      this.minutes = Math.floor((difference % (1000 * 60 * 60)) / (1000 * 60));
      this.seconds = Math.floor((difference % (1000 * 60)) / 1000);

      // If the target date has passed, stop the countdown and set values to "00"
      if (difference <= 0) {
        debugger
        console.log(difference)
        clearInterval(this.interval);

        this.hours = "00";
        this.minutes = "00";
        this.seconds = "00";

        this.kaij()
      }
    },

    jump(path) {
      this.$router.push(path);
    },
    videoOpen(index, row) {
      console.log(index, row);
    },
  },
};
</script>

<style scoped lang="less">
.border {
  border: 1px solid #ddd !important;
}
.all-top {
  padding: 30px;
  width: calc(100% - 60px);
  .title {
    font-size: 16px !important;
    padding: 10px 0px 20px 0px !important;
  }
}
:deep(.el-table thead th) {
  height: 35px;
  line-height: 35px;
  border-bottom: 1px solid #dadada;
  padding: 0 10px;
  background-color: #efefef;
}
</style>
