<template>
  <div>
  </div>
</template>

<script>
export default {
  name: "App",
  components: {},
  methods: {
    // 添加判断方法
    isMobile() {
      let flag = navigator.userAgent.match(
        /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
      );
      return flag;
    },
  },
  mounted() {
    if (this.isMobile()) {
      console.log("跳转移动端");
      this.$router.push("/mobileHome");
      
    } else {
      console.log("跳转PC端");
      this.$router.push("/pcHome");
    }
  },
};
</script>