<template>
  <div class="header-pub clearfix">
    <div class="main-con logo clearfix">
      <div class="fxl img"><img src="../assets/logo.png"></div>
    </div>
  </div>
</template>

<script>
import axios from "axios";

export default {
  props:{
    tabSelect:{
      type:Number,
      default: 0
    }
  },
  data() {
    return {
      newDate: new Date(),
      timer: null,
    };
  },
  // 注入全局变量
  inject: ["imageUrl", "serviceUrl"],
  mounted() {
    let that = this;
    this.timer = setInterval(() => {
      that.newDate = new Date() // 修改数据date
    }, 1000)
  },

  methods: {
    jump(path) {
      this.$router.push(path);
    },
    dateFormat(time) {
      let date = new Date(time)
      let year = date.getFullYear()
      let wk = date.getDay()
      let month = date.getMonth() + 1 < 10 ? "0" + (date.getMonth() + 1) : date.getMonth() + 1
      let day = date.getDate() < 10 ? "0" + date.getDate() : date.getDate()
      let hours = date.getHours() < 10 ? "0" + date.getHours() : date.getHours()
      let minutes = date.getMinutes() < 10 ? "0" + date.getMinutes() : date.getMinutes()
      // let seconds = date.getSeconds() < 10 ? "0" + date.getSeconds() : date.getSeconds()
      let weeks = ["星期日", "星期一", "星期二", "星期三", "星期四", "星期五", "星期六"]
      let week = weeks[wk]
      // 拼接
      return year + "年" + month + "月" + day + "日" + " " + hours + ":" + minutes + " " + week
    },
  },
};
</script>

<style lang="less" scoped>
.header-pub {
  background: -webkit-gradient(linear,left top,left bottom,from(#fff),color-stop(60%,#fefefe),color-stop(90%,#f0f0f0),to(#fff));
  background: linear-gradient(180deg,#fff,#fefefe 60%,#f0f0f0 90%,#fff);
  .logo {
    padding: 5px 0px;
    margin:0px 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .img {
      width: 50%;

      img {
        height: 70px;
      }
    }

    .time {
      font-size: 18px;
      color: #a4a4a4;
      width: 50%;
      text-align: right;
    }
  }
  .menu{
    border-top: 1px solid #dadada;
    ul{
      margin:0px 0px 20px 0px;
      border-bottom: 5px solid #eee222;
      line-height:40px;
      text-align: center;
      color:#fff;
      font-size:16px;
      font-weight: bold;
      li{
        cursor: pointer;
        background:#c72a23;
        &.focus{
          color:#eee222;
        }
        &:nth-child(2){
          background:#469fe4;
        }
        &:nth-child(3){
          background:#5abd38;
        }
        &:nth-child(4){
          background:#d1a538;
        }
        &:nth-child(5){
          background:#6235be;
        }
        &:nth-child(6){
          background:#b53eb0;
        }
      }
    }
  }
}
</style>
