<template>
  <div>
    <PcHeader :tab-select="tabSelect" />
    <div class="main-con clearfix">
      <div class="detail-con">
        <div class="article" v-html="neir" />
      </div>
    </div>

    <PcFooter />
  </div>
</template>

<script>
import PcHeader from "./PcHeader";
import PcFooter from "./PcFooter";
import axios from "axios";

export default {
  components: {
    PcHeader,
    PcFooter,
  },

  data() {
    return {
      neir: null
    };
  },

  // 注入全局变量
  inject: ["imageUrl", "serviceUrl"],

  mounted() {
    let that = this;

    axios
      .get(that.serviceUrl + "/neir/ayNir/list", {
        params: { fenl: "3" },
      })
      .then((resp) => {
        console.log(resp);
        that.neirList = resp.data.result.records;
        that.neir = this.neirList[0].neir;
      })
      .catch((error) => {
        console.log(error);
      });
  }
};
</script>

<style scoped></style>
