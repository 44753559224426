<template>
  <div class="footer main-con clearfix">
    版權所有 不得轉載 © 2020 香港公益539
  </div>
</template>

<style>
.footer {
  font-size: 16px;
  text-align: center;
  padding: 20px 0px;
  color: #a4a4a4;
  border-top: 5px solid #eee222;
}
</style>
