<template>
  <div>
    <PcHeader :tab-select="tabSelect" />
    <div class="main-con clearfix">
      <img src="../assets/about.png" />
    </div>
    <div class="clear h20"></div>
    <div class="header">
      <ul>
        <li
          :class="{ active: index == selectTabs }"
          v-for="(item, index) in neirList"
          :key="item"
          @click="tabChange(index, item)"
        >
          <div class="activeColor">{{ item.biaot }}</div>
        </li>
      </ul>
    </div>
    <div class="clear"></div>
    <div class="main-con clearfix">
      <div class="detail-con" v-html="tabContent"></div>
    </div>
    <PcFooter />
  </div>
</template>

<script>
import PcHeader from "./PcHeader";
import PcFooter from "./PcFooter";
import axios from "axios";

export default {
  components: {
    PcHeader,
    PcFooter,
  },

  data() {
    return {
      neirList: [],
      kaijggList: [],
      lunbtList: [],
      daohcdList: [],
      tabSelect: 4,
      selectTabs: 0,
      tabContent: {},
    };
  },

  // 注入全局变量
  inject: ["imageUrl", "serviceUrl"],

  mounted() {
    let that = this;

    axios
      .get(that.serviceUrl + "/neir/ayNir/list", {
        params: { fenl: "2" },
      })
      .then((resp) => {
        console.log(resp);
        that.neirList = resp.data.result.records;
        this.tabContent = this.neirList[0].neir;
      })
      .catch((error) => {
        console.log(error);
      });
  },

  methods: {
    jump(path) {
      this.$router.push(path);
    },
    tabChange(index, item) {
      this.selectTabs = index;
      this.tabContent = item.neir;
    },
  },
};
</script>

<style scoped></style>
