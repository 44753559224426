import { createApp } from 'vue'
import App from './App.vue'
import {createRouter, createWebHashHistory}from 'vue-router'
import './assets/public.less'
import 'element-plus/theme-chalk/index.css'

// 图标和组件需要分开引入
import ElementPlus from 'element-plus';

// 1. 定义路由组件.
// 也可以从其他文件导入
import Index from './components/Index'
import PcHome from './components/PcHome'
import KaiJgg from './components/KaiJgg'
import KaiJsp from './components/KaiJsp'
import FaQ from './components/FaQ'
import AboutUs from './components/AboutUs'
import ContactUs from './components/ContactUs'

import MobileHome from './components/MobileHome'
import MobileKaiJgg from './components/MobileKaiJgg'
import MobileKaiJsp from './components/MobileKaiJsp'
import MobileFaQ from './components/MobileFaQ'
import MobileAboutUs from './components/MobileAboutUs'
import MobileContactUs from './components/MobileContactUs'

// 2. 定义一些路由
// 每个路由都需要映射到一个组件。
// 我们后面再讨论嵌套路由。
const routes = [
  { path: '/', component: Index },
  { path: '/pcHome', component: PcHome },
  { path: '/lotteryAnnouncement', component: KaiJgg },
  { path: '/lotteryVideo', component: KaiJsp },
  { path: '/faq', component: FaQ },
  { path: '/aboutUs', component: AboutUs },
  { path: '/contactUs', component: ContactUs },

  { path: '/mobileHome', component:MobileHome },
  { path: '/mobileLotteryAnnouncement', component: MobileKaiJgg },
  { path: '/mobileLotteryVideo', component: MobileKaiJsp },
  { path: '/mobileFaq', component: MobileFaQ },
  { path: '/mobileAboutUs', component: MobileAboutUs },
  { path: '/mobileContactUs', component: MobileContactUs },
]

// 3. 创建路由实例并传递 `routes` 配置
// 你可以在这里输入更多的配置，但我们在这里
// 暂时保持简单
const router = createRouter({
  // 4. 内部提供了 history 模式的实现。为了简单起见，我们在这里使用 hash 模式。
  history: createWebHashHistory(),
  routes, // `routes: routes` 的缩写
})

// 5. 创建并挂载根实例
const app = createApp(App)
//确保 _use_ 路由实例使
//整个应用支持路由。
app.use(router)
app.use(ElementPlus)
// 全局变量

app.provide('imageUrl', 'https://goldgoldgold.life/amyh/jeecg-boot/sys/common/static/')
app.provide('serviceUrl', 'https://goldgoldgold.life//amyh/jeecg-boot')

// app.provide('imageUrl', 'https://wpp9889.cc/amyh/jeecg-boot/sys/common/static/')
// app.provide('serviceUrl', 'https://wpp9889.cc/amyh/jeecg-boot')

// app.provide('imageUrl', 'http://MS-YDFKBCLJMPNU:8080/jeecg-boot/sys/common/static/')
// app.provide('serviceUrl', 'http://MS-YDFKBCLJMPNU:8081/jeecg-boot')

app.mount('#app')

// 现在，应用已经启动了！