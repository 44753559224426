<template>
  <div class="mobile">
    <MobileHeader :tab-select="tabSelect" />
    <div class="main-con clearfix">
      <div class="pad10 clearfix">
        <div class="notice pad10 auto">
          <p class="title">重要通知：</p>
          <p class="con">
            香港公益539攪珠直播時間為每周一.周三.周五晚
            <span class="font-red"> 21:30 </span>
            (北京時間)，敬請查看。
          </p>
        </div>
      </div>
      <div class="clear"></div>
      <div class="search-bar">
        <div class="search-bar-title">
          <p class="title">開獎公告</p>
        </div>
      </div>
      <div class="block-left all-top clearfix">
        <div class="con clearfix">
          <div class="l-con">
            <div class="title" style="color: #999; padding: 5px 0px !important">
              第 <span class="font-red">{{ qih }}</span> 期開獎號碼
            </div>
            <div class="num one">
              <span class="ball small mar10"
                ><span :class="ballColor(kaijhm[0])">{{
                  kaijhm[0]
                }}</span></span
              >
              <span class="ball small mar10"
                ><span :class="ballColor(kaijhm[1])">{{
                  kaijhm[1]
                }}</span></span
              >
              <span class="ball small mar10"
                ><span :class="ballColor(kaijhm[2])">{{
                  kaijhm[2]
                }}</span></span
              >
              <span class="ball small mar10"
                ><span :class="ballColor(kaijhm[3])">{{
                  kaijhm[3]
                }}</span></span
              >
              <span class="ball small mar10"
                ><span :class="ballColor(kaijhm[4])">{{
                  kaijhm[4]
                }}</span></span
              >
              <span class="ball small mar10"
                ><span :class="ballColor(kaijhm[5])">{{
                  kaijhm[5]
                }}</span></span
              >
              <span
                class="ball font-red small mar10"
                style="font-size: 20px; padding: 0px 0px 0px 5px"
                >+</span
              >
              <span class="ball small"
                ><span :class="ballColor(kaijhm[6])">{{
                  kaijhm[6]
                }}</span></span
              >
            </div>
          </div>
          <div class="clear h10"></div>
          <div class="clear bottom-line h10"></div>
          <div class="clear h10"></div>
          <div class="r-con">
            <div class="title">下期開獎時間：{{ b }}</div>
            <div class="lotteryTime clearfix">
              <div class="leftTime clearfix">
                <div class="fxl hour">
                  <span class="num-border">{{ Math.floor(hours / 10) }}</span>
                  <span class="num-border">{{ hours % 10 }}</span>
                </div>
                <span class="fxl dot dot1">:</span>
                <div class="fxl minute fxl">
                  <span class="num-border">{{ Math.floor(minutes / 10) }}</span>
                  <span class="num-border">{{ minutes % 10 }}</span>
                </div>
                <span class="fxl dot dot2">:</span>
                <div class="second fxl">
                  <span class="num num-border">{{
                    Math.floor(seconds / 10)
                  }}</span>
                  <span class="num num-border">{{ seconds % 10 }}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="clear h20"></div>
    <ul class="search-field">
      <li class="day active">今年</li>
    </ul>
    <div class="main-con table clearfix">
      <ul class="list-one clearfix" v-for="(item, index) in kaijggList">
        <li class="clearfix pos-rel" @click="videoOpen(index, item)">
          <div class="right pos-abs video-detail">
            <img
              src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACcAAAAdCAYAAAApQnX+AAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAyNpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuNi1jMTQ4IDc5LjE2NDAzNiwgMjAxOS8wOC8xMy0wMTowNjo1NyAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvIiB4bWxuczp4bXBNTT0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL21tLyIgeG1sbnM6c3RSZWY9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9zVHlwZS9SZXNvdXJjZVJlZiMiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIDIxLjEgKFdpbmRvd3MpIiB4bXBNTTpJbnN0YW5jZUlEPSJ4bXAuaWlkOjhEMjkzNzg5NTkzMDExRUFBNTlCOEVDMzE0QTIyRTc4IiB4bXBNTTpEb2N1bWVudElEPSJ4bXAuZGlkOjhEMjkzNzhBNTkzMDExRUFBNTlCOEVDMzE0QTIyRTc4Ij4gPHhtcE1NOkRlcml2ZWRGcm9tIHN0UmVmOmluc3RhbmNlSUQ9InhtcC5paWQ6OEQyOTM3ODc1OTMwMTFFQUE1OUI4RUMzMTRBMjJFNzgiIHN0UmVmOmRvY3VtZW50SUQ9InhtcC5kaWQ6OEQyOTM3ODg1OTMwMTFFQUE1OUI4RUMzMTRBMjJFNzgiLz4gPC9yZGY6RGVzY3JpcHRpb24+IDwvcmRmOlJERj4gPC94OnhtcG1ldGE+IDw/eHBhY2tldCBlbmQ9InIiPz69hXKAAAABFElEQVR42mK8wSDIMMiAORAvBOITLIPMYR5AvB6IOYBYFeQ4RyCuBmIBGlv8B4h3AnEjEP/DIm8PxGuhDgMBJkZgtD4HMiToGDpeQLwdS1SCHM6PLMhEZ4eBAHoiVwHizegOgzluIIEANBRFsUkOpOMYgXgZNOQYBpvjyoDYE5+CgXKcMjTXMgxGxwUCMftgdRxRhf9A59ZRx406btRxo44bro77N5gdtxGIfw1Wx90E4trBnOa6oSE4KB33H4jjgfjyYM2tH4HYB4hf43LcSzo76BMa/xEQu0EditGuigLieiCWobGjvgLxNizdQhC4AMT+UHkueCdjkA1HgDr4m4CYB4h/DrYaYj8QOwDxHSCeDRBgAJcHKVMZVLZVAAAAAElFTkSuQmCC"
              alt=""
            />
          </div>
          <div class="fxl tit">期號</div>
          <div class="fxl con">第 {{ item.qih }} 期</div>
          <div class="clear h10"></div>
          <div class="fxl tit">開獎時間</div>
          <div class="fxl con">{{ item.kaijrq }}</div>
          <div class="clear h10"></div>
          <div class="fxl tit">中獎號碼</div>
          <div class="fxl con">
            <div class="clearfix" style="margin: -15px 0px 0px 10px">
              <span class="ball small mar10">
                <span :class="ballColor(item.kaijhm.split(',')[0])">{{
                  item.kaijhm.split(",")[0]
                }}</span>
              </span>
              <span class="ball small mar10">
                <span :class="ballColor(item.kaijhm.split(',')[1])">{{
                  item.kaijhm.split(",")[1]
                }}</span>
              </span>
              <span class="ball small mar10">
                <span :class="ballColor(item.kaijhm.split(',')[2])">{{
                  item.kaijhm.split(",")[2]
                }}</span>
              </span>
              <span class="ball small mar10">
                <span :class="ballColor(item.kaijhm.split(',')[3])">{{
                  item.kaijhm.split(",")[3]
                }}</span>
              </span>
              <span class="ball small mar10">
                <span :class="ballColor(item.kaijhm.split(',')[4])">{{
                  item.kaijhm.split(",")[4]
                }}</span>
              </span>
              <span class="ball small mar10">
                <span :class="ballColor(item.kaijhm.split(',')[5])">{{
                  item.kaijhm.split(",")[5]
                }}</span>
              </span>
              <span class="ball font-red mar10">+</span>
              <span class="ball small">
                <span :class="ballColor(item.kaijhm.split(',')[6])">{{
                  item.kaijhm.split(",")[6]
                }}</span>
              </span>
            </div>
          </div>
        </li>
      </ul>
    </div>
    <MobileFooter :tab-select="tabSelect" />
  </div>
</template>

<script>
import MobileHeader from "./MobileHeader";
import MobileFooter from "./MobileFooter";
import axios from "axios";

export default {
  components: {
    MobileHeader,
    MobileFooter,
  },

  data() {
    return {
      a: null,
      b: null,
      zuixyq: [],
      interval: null,
      targetDate: null,
      days: 0,
      hours: 0,
      minutes: 0,
      seconds: 0,

      qih: "",
      kaijhm: [],

      kaijggList: [],
      lunbtList: [],
      daohcdList: [],
      tabSelect: 1,
    };
  },

  computed: {
    ballColor(num) {
      return function (num) {
        const red = [
          1, 2, 7, 8, 12, 13, 18, 19, 23, 24, 29, 30, 34, 35, 40, 45, 46,
        ];
        const green = [
          5, 6, 11, 16, 17, 21, 22, 27, 28, 32, 33, 38, 39, 43, 44, 49,
        ];
        const blue = [
          3, 4, 9, 10, 14, 15, 20, 25, 26, 31, 36, 37, 41, 42, 47, 48,
        ];

        if (red.includes(parseInt(num))) return "balls red";
        if (green.includes(parseInt(num))) return "balls green";
        if (blue.includes(parseInt(num))) return "balls blue";
      };
    },
  },

  // 注入全局变量
  inject: ["imageUrl", "serviceUrl"],

  mounted() {
    let that = this;

    axios
      .get(that.serviceUrl + "/sys/api/queryDictItemsByCode", {
        params: { code: "zuixyq" },
      })
      .then((resp) => {
        that.zuixyq = resp.data;

        let tmp = that.zuixyq[1].value;
        tmp = tmp.replace(" ", "T");
        that.targetDate = new Date(tmp + "+08:00");

        this.calculateTime();
        // 每秒更新一次倒计时
        this.interval = setInterval(() => {
          this.calculateTime();
        }, 1000);

        that.a = that.zuixyq[0].value;
        that.b = that.zuixyq[1].value;
      })
      .catch((error) => {
        console.log(error);
      });

      this.loadData()
  },

  methods: {
    loadData() {
      let that = this

      axios
      .get(that.serviceUrl + "/kaijgg/ayKaijgg/list", {
        params: {
          shifxs: "1",
          column: "qih",
          order: "desc",
          pageNo: 1,
          pageSize: 365,
        },
      })
      .then((resp) => {
        that.kaijggList = resp.data.result.records;
        that.kaijhm = resp.data.result.records[0].kaijhm.split(",");
        that.qih = resp.data.result.records[0].qih;
      })
      .catch((error) => {
        console.log(error);
      });
    },

    kaij() {
      let that = this

      axios
      .put(that.serviceUrl + "/kaijgg/ayKaijgg/kaij", {
        params: {
        },
      })
      .then((resp) => {
        that.loadData()
      })
      .catch((error) => {
        console.log(error);
      });
    },

    calculateTime() {
      const now = new Date();
      const difference = this.targetDate - now;

      // Calculate total hours remaining
      const totalHours = Math.floor(difference / (1000 * 60 * 60));

      // Calculate days, hours, minutes, and seconds
      this.hours = totalHours;
      this.minutes = Math.floor((difference % (1000 * 60 * 60)) / (1000 * 60));
      this.seconds = Math.floor((difference % (1000 * 60)) / 1000);

      // If the target date has passed, stop the countdown and set values to "00"
      if (difference <= 0) {
        clearInterval(this.interval);

        this.hours = "00";
        this.minutes = "00";
        this.seconds = "00";

        this.kaij()
      }
    },

    jump(path) {
      this.$router.push(path);
    },

    videoOpen(index, row) {
      console.log(index, row);
    },
  },
};
</script>

<style scoped lang="less">
.border {
  border: 1px solid #ddd !important;
}
.bottom-line {
  border-bottom: 1px solid #c4c4c4;
}
.all-top {
  border-bottom: 1px solid #c4c4c4;
  padding: 20px;
  width: calc(100% - 40px);
  .title {
    font-size: 16px !important;
    padding: 10px 0px 20px 0px !important;
  }
}
.mar10 {
  margin-left: -6px !important;
}
:deep(.el-table thead th) {
  height: 35px;
  line-height: 35px;
  border-bottom: 1px solid #dadada;
  padding: 0 10px;
  background-color: #efefef;
}
.search-bar {
  width: 100%;
  padding: 10px;
  border-top: 1px solid #c4c4c4;
  border-bottom: 1px solid #c4c4c4;
  background-color: #fff;
}
.search-bar-title {
  height: 100%;
  font-size: 18px;
  color: #343434;
  font-weight: 700;
}
.search-field {
  width: calc(100% - 20px);
  margin: 10px;
  font-size: 16px;
  height: 24px;
  line-height: 24px;
  background-color: #fff;
}

.search-field .day {
  width: 60px;
  text-align: center;
  border-radius: 4px;
  margin-right: 10px;
}

.search-field .day.active {
  background-color: #d80011;
  color: #fff;
}
.list-one {
  li {
    padding: 20px 0px 10px 0px;
    border-bottom: 1px solid #c4c4c4;
    .tit {
      width: 70px;
      margin-right: 5px;
      text-align: right;
      font-weight: bold;
      color: #d80011;
    }
    .con {
      width: calc(100% - 100px);
    }
  }
}
.video-detail {
  right: 20px;
  top: 50%;
  margin-top: -5px;
  img {
    width: 20px;
    color: #d80011;
  }
}
</style>
